import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useEffect, useRef, useState} from "react";
import {wsapi} from "../../../api/wsapi";
import Header from "../../../navigation/Header";
import Interaction from "../interaction/Interaction";
import {useTranslation} from "react-i18next";

import {DirectionChange, LightPathType, LightShape, TherapyConfiguration, TherapyState} from "../therapy-configuration";
import {
    setTherapyConfiguration
} from "../therapyConfigurationSlice";
import "./VisitorSession.css";
interface VisitorSessionProps {
    sessionId: string;
}
const VisitorSession: React.FC<VisitorSessionProps> = ({sessionId}) => {
    const [isTherapistJoined, setIsTherapistJoined] = useState<boolean>(false);
    const {t} = useTranslation();

    // const profileData = useSelector((state: RootState) => state.profile);

    const therapyConfiguration = useSelector((state: RootState) => state.therapyConfiguration);

    // const therapyConfiguration = useSelector((state: RootState) => state.therapyConfiguration);
    const dispatch = useDispatch();
    useEffect(() => {
        wsapi().emit('joinSession', { session: 'session-' + sessionId, role: "client" });
        // wsapi().emit('joinSession', { session: 'session-' + sessionId, role: "client" });

        wsapi().on('configurationChange', (message) => {
            const newConfiguration = message.therapyConfiguration;
            // console.log('Received message from server:', message);
            dispatch(setTherapyConfiguration(newConfiguration));
        });

        wsapi().on('therapistJoined', (message) => {
            setIsTherapistJoined(true);
        });
        wsapi().on('therapistDisconnected', (message) => {
            setIsTherapistJoined(false);
        });
        document.body.style.overflow="hidden";
    }, [])
    return <div>
        {/*Visitore id: {sessionId}*/}
        <div className={"interaction-part" + therapyConfiguration.colorPreset === "dark" ? " dark-interaction-part": " light-interaction-part"}>
            <Interaction isOppositePersonJoined={isTherapistJoined} />
            {isTherapistJoined ?
            <div className={"session-status"}>{t("session in progress")}</div>
                :
            <div className={"session-status waiting-therapist"}><span>{t("waiting for therapist to join")}</span></div>
            }
        </div>
    </div>
}
export default VisitorSession;
