import {toast} from "react-toastify";
import {makeLink, translate} from "../../common/languages-helper";
import {useTranslation} from "react-i18next";

export const copyInviteText = (invite:any, profileData:any) => {
    let text = translate("EMDRlink | session #%s1\n\n Hey, please join session by clicking the link: %s2\n\n Looking forward to talking to you!\n\n Therapist: #%s3").replace(/%s1/g,invite.inviteeName).replace(/%s2/g, getLinkAddress(invite)).replace(/%s3/g, profileData.name);
    navigator.clipboard.writeText(text);
    toast.success(translate("Invitation to %s has been copied to clipboard.").replace(/%s/g,invite.inviteeName));
    return text;
}
export const getLinkAddress = (invite:any) => {
    return window.location.protocol + "//" + window.location.host + makeLink("/jsession-")+invite._id;
}
