import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import Header from "../../navigation/Header";
import {useTranslation} from "react-i18next";
import "./Portal.css";
interface PortalProps {
}
const Portal: React.FC<PortalProps> = ({}) => {
    const {t} = useTranslation();
    const profileData = useSelector((state: RootState) => state.profile);
    return <>
        <div className={"patient-info"}>
            <h1> {t("Patient portal")}</h1>
            <br />
             {t("Name")}: {profileData.name}<br />
             {t("Email")}: {profileData.email}<br />
        </div>
    </>
}
export default Portal;
