import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useTranslation} from "react-i18next";
import "./Home.css";
import {Link} from "react-router-dom";
import {makeLink} from "../../common/languages-helper";

interface HomeProps {
}
const Home: React.FC<HomeProps> = ({}) => {
    const profileData = useSelector((state: RootState) => state.profile);
     const {t} = useTranslation();
    return <>
        <div className={"patient-info"}>
            <h1>{t("Welcome to EMDRLINK.PL")}</h1>
            <br />
            {t("Name")}: {profileData.name}<br />
            {t("Email")}: {profileData.email}<br />
            <br />
            <Link to={makeLink("/therapist")}> {t("Go to the therapist portal")}</Link>
        </div>
    </>
}
export default Home;
