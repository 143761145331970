import React, {useState, useEffect} from 'react';
import {PageMetaData} from "./components/common/paget-meta-data";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store";
import {LanguageContext} from "./LanguageContext";
import {useParams} from "react-router-dom";
import i18n from "./i18n";
import {getAvailableLanguages} from "./components/common/languages-helper";
import {useTranslation} from "react-i18next";
import TherapistDashboard from "./components/pages/therapist-dashboard/TherapistDashboard";
import TherapySession from "./components/pages/therapy-session/TherapySession";
import Loader from "./components/navigation/Loader";
import Portal from "./components/pages/portal/Portal";
import HostSession from "./components/pages/therapy-session/host-session/HostSession";
import VisitorSession from "./components/pages/therapy-session/visitor-session/VisitorSession";
import BeforeClientStartJoin from "./components/pages/therapy-session/visitor-session/BeforeClientStartJoin";
import Home from "./components/pages/home/Home";
import LoginNavigation from "./components/navigation/login/LoginNavigation";
import Profile from "./components/pages/profile/Profile";

//function RoutesHandler(props:{setPageMetaData:any, setCurrentWizardStep:any, isAuthenticated:boolean, isPageLoading:boolean, loginWithRedirect:any, profileData:any, setProfileData:any, setIsTalkingPage:any, setIsHomePage:any, loginFunction:any}) {
interface RoutesHandlerProps {
    isAuthenticated:boolean,
    loginFunction:any,
    logout:any,
    isLoading: boolean
}
const RoutesHandler:React.FC<RoutesHandlerProps> = ({isAuthenticated,isLoading,logout,loginFunction}) => {
    const { setLang } = React.useContext(LanguageContext);
    const {t} = useTranslation();
    let { langId } = useParams<{ langId: string }>();
    let { pageUrlPart } = useParams<{ pageUrlPart: string }>();

    React.useEffect(() => {
        //@ts-ignore
        if(getAvailableLanguages().includes(langId)) {
            i18n.changeLanguage(langId);
        }
    }, [langId, setLang]);

    const profileData = useSelector((state: RootState) => state.profile);
    // const dispatch = useDispatch();
    const availableLanguages = getAvailableLanguages();
    if(!pageUrlPart && langId && !availableLanguages.includes(langId)) {
        //when the first part of the url is not a language
        pageUrlPart = langId;
        langId = 'pl';
    }
    if(!langId && !pageUrlPart) {
        //when it is a main page - check preferred language
        if(localStorage.getItem('lang') && localStorage.getItem('lang')!== 'pl' && availableLanguages.includes(localStorage.getItem('lang')??"")) {
            //when visiting homepage without the language prefix, and we have in local storage that the language is not English - redirect
            window.location.href =  "/" + localStorage.getItem('lang');
            return <div></div>;
        } else {
            langId = 'pl';
        }
    }
    if(isLoading || isAuthenticated && !profileData._id) {
        return <Loader />;
        // return <LoginNavigation isAuthenticated={} loginFunction={} logout={}
    }

    if(!langId && !pageUrlPart) {
        //main page
    }  else if(!langId || langId === "pl") {
        //when no language is set - it is English
        langId = 'pl';
        //when it is a default language - we remove the language part
        localStorage.removeItem('lang');
    } else if(langId !== 'pl') {
        localStorage.setItem('lang', langId);
    }

    if(pageUrlPart?.substring(0,"jsession-".length) === "jsession-") {
        const sessionId = pageUrlPart.split('-').pop();
        if(sessionId) {
            return <BeforeClientStartJoin sessionId={sessionId}/>
        } else {
            return <div>not found</div>;
        }
    } else if(pageUrlPart?.substring(0,"csession-".length) === "csession-") {
        const sessionId = pageUrlPart.split('-').pop();
        if(sessionId) {
            return <VisitorSession sessionId={sessionId}/>
        } else {
            return <div>not found</div>;
        }
    } else if(!isAuthenticated) {
        return <div style={{textAlign:"center", marginTop:"45vh"}}> {' '}
            <a href={"#"} onClick={(event:any)=>{loginFunction(null,event)}}>{t("Sign In")}</a> /  <a href={"#"} onClick={(event:any) => loginFunction({authorizationParams: { screen_hint: "signup" }},event)}>{t("Sign Up")}</a>
        </div>
    } else if(!profileData.agreement) {
        return <Profile formTitle={t("Please complete your profile")} />
    } else if(!pageUrlPart) {
        return <Home />
    }
    else if(pageUrlPart === "therapist") {
        return <TherapistDashboard />
    } else if(pageUrlPart?.substring(0,"session-".length) === "session-") {
        const sessionId = pageUrlPart.split('-').pop();
        if(sessionId) {
            return <HostSession sessionId={sessionId}/>
        } else {
            return <div>not found</div>;
        }
    } else if(pageUrlPart === "portal"){
        return <Portal />
    } else if(pageUrlPart === "profile"){
        return <Profile />
    } {
        return <div>404 NOT FOUND</div>
    }
}
export default RoutesHandler;
