import {useSelector} from "react-redux";
import {RootState} from "../../store";
import React, {useEffect, useState, useRef} from 'react';
import "./Header.css";
import LoginNavigation from "./login/LoginNavigation";
import MobileMenu from "./MobileMenu/MobileMenu";
import LanguageMenu from "./LanguageMenu/LanguageMenu";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeLink} from "../common/languages-helper";

interface HeaderProps {
    isAuthenticated:boolean,
    loginFunction:any,
    logout:any
}

const Header: React.FC<HeaderProps> = ({isAuthenticated, loginFunction, logout}) => {
    const {t} = useTranslation();
    const profileData = useSelector((state: RootState) => state.profile);
    const isSessionWindow = () => {
        return document.location.href.includes('/csession-') || document.location.href.includes('/session-');
    }
    const firstRowMenu:any = [
        {
            'title': t('Main Page'),
            'url': 'https://emdrlink.pl'
        },
        {
        'title': t('EMDRlink Device'),
            'url':'#'
        },
        {
            'title': t('Find Therapist'),
            'url':'#'
        },
        {
            'title': t('Training and Development'),
            'url':'#'
        },
        {
            'title': t('Contact'),
            'url':'#'
        },
    ]
    const secondRowMenu:any = [
            {
                'title': t('Information about the program'),
                'url':'#'
            },
            {
                'title': t('Pricing'),
                'url':'#'
            },
            {
                'title': t('FAQ'),
                'url':'#'
            },
            {
                'title': t('Instructions'),
                'url':'#'
            },
            {
                'title': t('Technical support'),
                'url':'#'
            }
        ]
    return <div className={"header" + (isSessionWindow() ? " session-window" :"")}>
        <div className={"menu-top-row"}>
            {
                firstRowMenu.map((menuItem:any,index:number)=>{
                    return <div key={index} className={"menu-top-row-item"}><a href={menuItem.url}>{menuItem.title}</a></div>
                })
            }
            <LanguageMenu />
        </div>
        <div className={"second-menu-row"}>
            <div className={"header-logo"}>
                <Link to={"/"}>
                    <img src={"/logos/emdrlink.png"}></img>
                </Link>
            </div>
            {
                secondRowMenu.map((menuItem:any,index:number)=>{
                    return <div key={index} className={"menu-second-row-item"}><a href={menuItem.url}>{menuItem.title}</a></div>
                })
            }

            <div className={"login-info"}>
            <span className={"mobile"}>
              <LanguageMenu />
             </span>
            {
                !isSessionWindow() && <LoginNavigation isAuthenticated={isAuthenticated} loginFunction={loginFunction} logout={logout} />

            }
                <div className={"hamburger-menu"}>
                       {/*  &#9776; This is the hamburger icon */}
                      <MobileMenu firstRowMenu={firstRowMenu} secondRowMenu={secondRowMenu} />
                </div>

            </div>
        </div>
    </div>
}
export default Header;
