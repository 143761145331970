import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import Header from "../../navigation/Header";
import InvitesTable from "./InvitesTable";
import {useTranslation} from "react-i18next";

import "./TherapistDashboard.css";

interface TherapistDashboardProps {

}
const TherapistDashboard: React.FC<TherapistDashboardProps> = ({}) => {
    const {t} = useTranslation();
    const profileData = useSelector((state: RootState) => state.profile);
    return (
        <>
            <div className={"therapist-name"}> {t("Therapist name")}: {profileData.name}</div>
            <InvitesTable />
        </>
    )
}
export default TherapistDashboard;
