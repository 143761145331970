import { useEffect } from 'react';

const useGoogleAnalytics = (trackingId: string) => {
 useEffect(() => {
   const scriptSrc = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
   const script = document.createElement('script');
   script.async = true;
   script.src = scriptSrc;
   document.body.appendChild(script);
    //@ts-ignore
   window.dataLayer = window.dataLayer || [];
   function gtag() {
   //@ts-ignore
     window.dataLayer.push(arguments);
   }
    //@ts-ignore
   gtag('js', new Date());
   //@ts-ignore
   gtag('config', trackingId);

   return () => {
     document.body.removeChild(script);
   };
 }, [trackingId]);
};

export default useGoogleAnalytics;
